 .App {
  margin: 0;
  padding: 0;
}

@keyframes comeIn {
  0% { transform: scale(0) }
  75% { transform: scale(1.03) }
  100% { transform: scale(1) }
}

.xmasonry .xblock {
  animation: comeIn ease 0.5s;
  animation-iteration-count: 1;
  transition: left .3s ease, top .3s ease;
}

.content {
  width: 50%;
  margin: 0px auto;
}

.embed-container {
  height: 0;
  width: 100%;
  padding-bottom: 56.25%; /* play with this until right */
  overflow: hidden;
  position: relative;
}

.embed-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}